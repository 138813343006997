<template>
  <div class="Part">
    <div class="input">
      <!-- 功能按钮 -->
      <el-button type="primary" size="mini" @click="editLog" v-if="authe.Hotel_Administration_Journal_WriteLog">写日志</el-button>
      <el-button type="primary" size="mini" @click="updateLog" v-if="authe.Hotel_Administration_Journal_ChangeLog">更新日志</el-button>
      <el-button type="primary" size="mini" @click="deleteLog" v-if="authe.Hotel_Administration_Journal_DeleteLog">删除日志</el-button>
      <el-button type="primary" size="mini" @click="logSet" v-if="authe.Hotel_Administration_Journal_LogSettings">日志设置</el-button>

      <!-- 表格筛选 -->
      <el-row style="padding: 5px 5px 5px 5px;">
        <el-date-picker
          v-model="scTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="scTypeChange"
          size="mini"
        ></el-date-picker>&nbsp;&nbsp;共享&nbsp;
        <el-switch v-model="isShare"></el-switch>&nbsp;&nbsp;&nbsp;&nbsp;日志类型&nbsp;
        <el-select v-model="scType" @change="scTypeChange" size="mini" style="width:110px">
          <el-option label="我的日志" value="1"></el-option>
          <el-option label="评阅日志" value="2"></el-option>
        </el-select>
      </el-row>
      <!-- 写日志对话框 -->
      <el-dialog
        :title="logFrom.title"
        :visible.sync="editLogDialog"
        :close-on-click-modal="false"
        width="800px"
        @close="closeLog"
        @open="show"
      >
        <div class="log">
          <el-form :model="logFrom" ref="logFrom">
            <el-form-item
              label="日计划"
              prop="plan"
              :rules="{required: true, message: '计划不能为空', trigger: 'blur'}"
            >
              <el-input
                type="textarea"
                v-model="logFrom.plan"
                :autosize="{ minRows: 2, maxRows: 6}"
              ></el-input>
            </el-form-item>
            <el-form-item label="工作记录">
              <el-input type="textarea" v-model="logFrom.job" :autosize="{ minRows: 2, maxRows: 6}"></el-input>
            </el-form-item>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="共享人" label-width="50px">
                  <el-cascader
                    v-model="logFrom.share"
                    :options="userNameList"
                    :props="{ expandTrigger: 'hover', multiple: true }"
                    clearable
                    size="small"
                    :show-all-levels="false"
                    style="width:100%"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-date-picker
                    v-model="logFrom.date"
                    type="datetime"
                    placeholder="选择日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    size="small"
                    style="width:100%"
                    :picker-options="pickerOptions0"
                    v-show="!reWrite"
                    :disabled="logFrom.title == '更新日志'"
                  ></el-date-picker>
                  <el-date-picker
                    v-model="logFrom.date"
                    type="datetime"
                    placeholder="选择日期"
                    format="yyyy-MM-dd  HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    size="small"
                    style="width:100%"
                    v-show="reWrite"
                    :disabled="logFrom.title == '更新日志'"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="工作小结"></el-form-item>
            <div class="edit_container">
              <quill-editor class="ql-editor" v-model="logFrom.content" ref="myQuillEditor" :options="editorOption"></quill-editor>
            </div>

          </el-form>
          <!-- 附件 -->
          <div class="accessory">
            <el-upload
              class="upload-demo"
              action="https://upload-z2.qiniup.com"
              :data="picData"
              :file-list="logFrom.imgUploadList"
              list-type="picture"
              :before-upload="beforeUpload"
              :on-success="handleAvatarSuccess"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
              :on-preview="handlePictureCardPreview"
            >
              <el-button size="small" type="primary">上传附件</el-button>
              <el-button size="small" type="primary" @click.stop="phoneUpload">手机上传</el-button>
              <el-button size="small" type="primary" @click.stop="getTempImg(logFrom.random)">
                <i class="el-icon-refresh"></i>
              </el-button>
            </el-upload>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editLogDialog = false">取 消</el-button>
          <el-button type="primary" @click="saveLog" v-show="logFrom.title=='写日志'">确 定</el-button>
          <el-button type="primary" @click="saveModiLog" v-show="logFrom.title=='更新日志'">确 定</el-button>
        </span>
      </el-dialog>
      <!--富文本图片上传-->
      <el-upload
        :data="picData2"
        class="avatar-uploader"
        action="https://upload-z2.qiniup.com"
        :before-upload="beforeUpload2"
        :on-success="success"
        :on-error="handleError"
        :show-file-list="false"
        style="display: none"
      >
        <i class="el-icon-plus avatar-uploader-icon" id="imgInput"></i>
      </el-upload>
      <!-- 删除对话框 -->
      <el-dialog title="您确定要删除该日志吗?" :visible.sync="deleteDialog" width="30%">
        <span>删除后，该日志将不可恢复</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteDialog = false">取 消</el-button>
          <el-button type="primary" @click="saveDeteleLog">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改对话框 -->
      <!-- <el-dialog title="编辑日计划" :visible.sync="editorDayPlanDialog" width="30%">
        <el-col :span="24" style="border:1px solid #ccc;margin-bottom:20px;">
          <el-input type="textarea" v-model="test"></el-input>
        </el-col>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editorDayPlanDialog = false">取 消</el-button>
          <el-button type="primary">确 定</el-button>
        </span>
      </el-dialog>-->
    </div>
    <!-- 数据表 -->
    <div style="text-align:center;">
      <el-table
        :data="logData"
        style="width: 100%"
        highlight-current-row
        :height="tableHeight"
        @row-click="selectRow"
        @row-dblclick="dbRow"
      >
        <el-table-column prop="jdrTitle" label="标题" show-overflow-tooltip></el-table-column>
        <el-table-column prop="jdrWorkRecord" label="内容" show-overflow-tooltip></el-table-column>

        <el-table-column prop="suStaffName" label="发布人"></el-table-column>
        <el-table-column prop="jdrReleaseTime" label="发布时间"></el-table-column>
        <el-table-column prop="commentNum" label="评论数"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <!-- 日志设置对话框 -->
    <el-dialog title="日志设置" :visible.sync="logSetDialog" width="30%">
      允许员工补写日志
      <el-switch v-model="reWrite" @change="reWriteChange"></el-switch>
      <br />
      <br />
      <br />
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <!-- 手机上传 -->
    <el-dialog
      :visible.sync="phoneUploadDia"
      title="微信扫一扫上传"
      width="280px"
      :close-on-click-modal="false"
      @close="closePhoneUploadDia"
    >
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="24">
          <canvas id="QRCode"></canvas>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog
      :visible.sync="accessoryDia"
      width="800px"
      :close-on-click-modal="false"
      title="查看附件"
      @close="closeAccessory"
    >
      <div style="min-height:400px">
        <el-upload
          class="upload-demo"
          action="https://upload-z2.qiniup.com"
          :file-list="logFrom.imgUploadList"
          :on-preview="handlePictureCardPreview"
          list-type="picture"
          disabled
        ></el-upload>
      </div>
    </el-dialog>
    <!-- 日志详细 -->
    <el-dialog
      :visible.sync="logDetailDia"
      :close-on-click-modal="false"
      width="800px"
      @close="closeLog"
    >
      <div class="project-detail">
        <div class="title">
          <i class="el-icon-star-on" style="color:#F4C902; margin-right:5px;"></i>
          {{logFrom.suStaffName}} {{logFrom.date}}
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>编辑人员：</span>
              {{logFrom.suStaffName}}
            </el-col>
            <el-col :span="12">
              <span>撰写时间：</span>
              {{logFrom.created}}
            </el-col>
          </el-row>
        </div>


        <p style="min-height:50px">{{logFrom.plan}}</p>
        <el-divider content-position="right">日计划</el-divider>

        <p style="min-height:50px">{{logFrom.job}}</p>
        <el-divider content-position="right">工作记录</el-divider>

        <p v-html="logFrom.content" style="min-height:100px"></p>
        <el-divider content-position="right">工作小结</el-divider>
        <el-button plai size="mini" @click="accessory" v-if="authe.Hotel_Administration_Journal_Annex">
          <i class="el-icon-picture-outline-round"></i>附件
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dateFtt, getUserId, getMyDay } from "../../assets/utils";
import { mapState } from "vuex";
import QRCode from "qrcode"; //引入生成二维码插件
import resizeImage from 'quill-image-resize-module' // 调整大小组件。
Quill.register('modules/resizeImage ', resizeImage )
export default {
  data() {
    return {
      editLogDialog: false,
      deleteDialog: false,
      editorDayPlanDialog: false,
      logSetDialog: false,
      dialogVisible: false,
      phoneUploadDia: false,
      accessoryDia: false,
      QRCodeMsg: "",
      logDetailDia: false,

      picData2: {
        token: ""
      },
      logFromRef: ["logFrom"],
      logFrom: {
        title: "",
        plan: "",
        job: "",
        share: [],
        date: dateFtt("yyyy-MM-dd hh:mm:ss", new Date()),
        content: "",
        imgUploadList: [],
        imgDelId: [],
        jdrId: ""
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },

      fileListL: [],
      tableHeight: window.innerHeight - 225,
      reWrite: false,
      reDayNum: "",

      scTime: null,
      isShare: false,
      scType: "1",

      logData: [],
      rowData: "",
      picData: {
        token: "",
        "x:co": "",
        "x:filename": "",
        "x:photoRandom": "", //十二位随机数
        "x:photoTableType": "", //审批or任务
        "x:photoMimeType": "", //文件or图片
        "x:photoBucket": "" //公用or私有
      },
      dialogImageUrl: "",

      total: 0,
      pageSize: 10,
      currentPage: 1,
      editorOption: {
        modules: {
        imageResize: {
          displayStyles: {
            backgroundColor: "black",
            border: "none",
            color: "white"
          },
          modules: [ 'Resize', 'DisplaySize'] 
        },
        toolbar: [
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link', 'image'],  //链接与图片
          [{ 'color': [] }, { 'background': [] }], //颜色和背景色
          ["bold", "italic", "underline", "strike"],       // 加粗 斜体 下划线 删除线
          [{ indent: "-1" }, { indent: "+1" }],            // 缩进
          [{ size: ["small", false, "large", "huge"] }],   // 字体大小
          [{ header: [1, 2, 3, 4, 5, 6] }],         // 标题
          [{ align: [] }],                                 // 对齐方式
          ["clean"],                                       // 清除文本格式
        ],
          history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false
          },
        }
      },
      noticeRow:{}
    };
  },
  created() {
    this.getLogData();
    this.getLogSetStatus();
  },
  computed: {
    ...mapState(["userNameList", "userName",'authe']),
    hintText() {
      return this.dailyPlanFrom.status.length == 0
        ? "设置为已完成"
        : "设置未完成";
    }
  },
  watch: {
    isShare: "getLogData",
    // 通过监听获取数据
    QRCodeMsg(val) {
      // 获取页面的canvas
      var msg = document.getElementById("QRCode");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, val, function(error) {
        console.log(error);
      });
    }
  },
  methods: {
    //打开日志对话框
    editLog() {
      this.logFrom.title = "写日志";
      this.logFrom.random = parseInt((Math.random() * 9 + 1) * 100000000000);
      this.editLogDialog = true;
    },
    //关闭日志对话框
    async closeLog() {
      if (this.logFrom.title == "更新日志") {
        console.log("更新日志");
        await this.recoverImg();
      }
      this.logFrom = {
        title: "",
        plan: "",
        job: "",
        share: [],
        date: dateFtt("yyyy-MM-dd hh:mm:ss", new Date()),
        content: "",
        imgUploadList: [],
        imgDelId: [],
        jdrId: ""
      };
      // this.resetForm(this.logFromRef, this);
    },
    //保存日志
    saveLog() {
      if (this.submitForm(this.logFromRef, this)) {
        let share = [];
        for (const i in this.logFrom.share) {
          share.push(this.logFrom.share[i][2]);
        }
        this.$axios({
          url: `/common1/dailyRecords`,
          method: "post",
          data: {
            jdrTitle: this.logFrom.plan,
            jdrWorkRecord: this.logFrom.job,
            jdrSharers: JSON.stringify(share),
            jdrReleaseTime: this.logFrom.date,
            jdrSummary: this.logFrom.content,
            jdrShareFlag: share.length != 0 ? 2 : "",
            tableType: "日志",
            randomNum: this.logFrom.random
          }
        }).then(res => {
          this.$message.success("日志添加成功");
          this.getLogData();
          this.editLogDialog = false;
        }).catch(err => {
          this.isError(err,this)
        });
      }
    },
    //保存更新日志
    saveModiLog() {
      if (this.submitForm(this.logFromRef, this)) {
        let share = [];
        for (const i in this.logFrom.share) {
          share.push(this.logFrom.share[i][2]);
        }
        this.$axios({
          url: `/common1/dailyRecords/${this.rowData.jdrId}`,
          method: "put",
          data: {
            jdrTitle: this.logFrom.plan,
            jdrWorkRecord: this.logFrom.job,
            jdrSharers: JSON.stringify(share),
            jdrUpdateTime: dateFtt("yyyy-MM-dd hh:mm:ss", new Date()),
            jdrSummary: this.logFrom.content,
            jdrShareFlag: share.length != 0 ? 2 : "",
            tableType: "日志",
            deleteImgList: this.logFrom.imgDelId,
            randomNum: this.logFrom.random
          }
        }).then(res => {
          this.$message.success("更新成功");
          this.getLogData();
          this.editLogDialog = false;
        }).catch(err => {
          this.isError(err,this)
        });
      }
    },
    //打开更新日志对话框
    updateLog() {
      if (!this.rowData) {
        this.$message.warning("请先选中一条日志");
      } else {
        this.logFrom.title = "更新日志";
        this.logFrom.random = parseInt((Math.random() * 9 + 1) * 100000000000);
        this.getPhoto();

        this.logFrom.plan = this.rowData.jdrTitle;
        this.logFrom.job = this.rowData.jdrWorkRecord;
        this.logFrom.share = getUserId(
          JSON.parse(this.rowData.jdrSharers),
          this.userName
        );

        this.logFrom.date = this.rowData.jdrReleaseTime;
        this.logFrom.content = this.rowData.jdrSummary;
        this.logFrom.jdrId = this.rowData.jdrId;
        this.editLogDialog = true;
      }
    },
    //打开删除日志对话框
    deleteLog() {
      if (!this.rowData) {
        this.$message.warning("请先选中一条日志");
      } else {
        this.deleteDialog = true;
      }
    },
    //保存删除日志
    saveDeteleLog() {
      this.$axios({
        url: `/common1/dailyRecords/${this.rowData.jdrId}`,
        method: "delete"
      }).then(res => {
        this.$message.success("删除成功");
        this.getLogData();
        this.rowData = "";
        this.deleteDialog = false;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //日志类型改变
    scTypeChange(val) {
      console.log(val);
      this.getLogData();
    },
    //打开日志设置对话框
    logSet() {
      this.getLogSetStatus();
      this.logSetDialog = true;
    },
    //打开修改日计划
    modifyDayPlan() {
      this.editorDayPlanDialog = true;
    },
    scDateChange() {
      this.rowData = "";
      this.getLogData();
    },

    //选中某一行
    selectRow(row) {
      this.noticeRow=row
      this.rowData = row;
    },
    //双击
    dbRow(row) {
      this.$axios({
        url: `/common1/dailyRecords/${row.jdrId}`,
        method: "get"
      }).then(res => {
        console.log(res);
        let data = res.data.result;
        let dataStr = data.jdrReleaseTime.substring(0, 10);
        this.logFrom.plan = data.jdrTitle;
        this.logFrom.job = data.jdrWorkRecord;
        this.logFrom.share = "";
        this.logFrom.date = `${dataStr}(${getMyDay(
          new Date(dataStr)
        )})工作汇报 `;
        this.logFrom.created = data.jdrReleaseTime;
        this.logFrom.content = data.jdrSummary;
        this.logFrom.suStaffName = data.suStaffName;
        this.logFrom.jdrId = data.jdrId;
      }).catch(err => {
        this.isError(err,this)
      })
      this.logDetailDia = true;
    },
    reWriteChange(val) {
      this.$axios({
        url: `/common1/companyVar/1`,
        method: "put",
        data: {
          scvSupplementSwitch: val == true ? 1 : 2
        }
      }).then(res => {
        this.$message.success("修改成功");
        this.logSetDialog = false;
      }).catch(err => {
        this.isError(err,this)
      });
    },
    getLogData() {
      this.$axios({
        url: `/common1/dailyRecords`,
        method: "get",
        params: {
          cursor: this.currentPage,
          limit: this.pageSize,
          jdrShareFlag: this.isShare == true ? 2 : "",
          startTime: this.scTime == null ? "" : `${this.scTime[0]} 00:00:00`,
          endTime: this.scTime == null ? "" : `${this.scTime[1]} 00:00:00`,
          queryFlag: this.scType
        }
      }).then(res => {
        console.log(res);
        this.logData = res.data.result.records;
        this.total = res.data.result.total;
        this.rowData = "";
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getLogSetStatus() {
      this.$axios({
        url: `/common1/companyVar/1`,
        method: "get"
      }).then(res => {
        console.log(res);
        this.reWrite = res.data.result.scvSupplementSwitch == 1 ? true : false;
      }).catch(err => {
        this.isError(err,this)
      })
    },

    //选择数据条数
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getLogData();
    },
    //具体那一页
    currentChange(current) {
      this.currentPage = current;
      this.rowData = "";
      this.getLogData();
    },

    //打开附件
    accessory() {
      this.getPhoto();
      this.accessoryDia = true;
    },
    //关闭附件
    closeAccessory() {
      this.logFrom.imgUploadList = [];
    },

    //手机上传
    phoneUpload() {
      if (this.logFrom.title == "写日志") {
        this.getPhoneUploadUrl(this.logFrom.random, "私有", "图片", null);
      } else {
        this.getPhoneUploadUrl(
          this.logFrom.random,
          "私有",
          "图片",
          this.logFrom.jdrId
        );
      }
      this.phoneUploadDia = true;
    },
    //关闭手机上传
    closePhoneUploadDia() {
      this.getTempImg(this.logFrom.random);
    },
    //恢复图片
    recoverImg() {
      this.$axios({
        url: `/common1/photos/recovery/${this.logFrom.jdrId}`,
        method: "put",
        params: {
          photoTableType: "日志"
        }
      }).then(res => {

      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取手机上传url
    getPhoneUploadUrl(random, bucket, fileType, tableId) {
      this.$axios({
        url: `/common1/photos/getMobUploadUrl`,
        method: "get",
        params: {
          photoRandom: random,
          photoBucket: bucket,
          photoMimeType: fileType,
          photoTableType: "日志",
          photoTableId: tableId,
          photoUrl: null,
          photoUserId: null
        }
      }).then(res => {
        console.log(res.data.result);
        this.QRCodeMsg = res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查询临时图片
    async getTempImg(random) {
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoRandom: random,
            photoTableType: "日志",
            photoStatus: "临时"
          }
        }).then(res => {
          imgdata = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        if (this.logFrom.title == "写日志") {
          if (this.logFrom.imgUploadList.length == 0) {
            this.logFrom.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.logFrom.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.logFrom.imgUploadList.push(item1);
              }
            }
          }
        } else {
          if (this.logFrom.imgUploadList.length == 0) {
            this.logFrom.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.logFrom.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.logFrom.imgUploadList.push(item1);
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    //查询已保存的图片
    async getPhoto() {
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoTableId: this.noticeRow.jdrId,
            photoTableType: "日志",
            photoStatus: "正常"
          }
        }).then(res => {
          imgdata = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        this.logFrom.imgUploadList = imgArr;
      } catch (err) {
        console.log(err);
      }
    },
    //获取下载凭证
    getDownloadToken(str) {
      return this.$axios({
        url: `/common1/photos/getDownloadUrl`,
        method: "get",
        params: {
          baseUrls: str
        }
      }).then(res => {
        return res.data.result[0].original;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取token
    getToken() {
      return this.$axios({
        url: "/common1/photos/token/callback",
        method: "get",
        params: {
          bucket: "私有"
        }
      }).then(res => {
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },

    //上传前
    async beforeUpload(file) {
      try {
        console.log(file);
        let getToken = await this.getToken();
        this.picData.token = getToken;
        file.type == "image/jpeg"
          ? (this.picData["x:photoMimeType"] = "图片")
          : (this.picData["x:photoMimeType"] = "文件");
        this.picData["x:filename"] = file.name;
        this.picData["x:co"] = sessionStorage.getItem("co");
        this.picData["x:photoTableType"] = "日志";
        this.picData["x:photoBucket"] = "私有";

        if (this.logFrom.title == "写日志") {
          this.picData["x:photoRandom"] = this.logFrom.random;
        } else {
          this.picData["x:photoTableId"] = this.logFrom.jdrId;
          this.picData["x:photoRandom"] = this.logFrom.random;
        }
      } catch (err) {
        console.log(err);
      }
    },
    //上传成功后
    handleAvatarSuccess(res, file, fileList) {
      this.logFrom.imgUploadList = fileList;
    },
    //删除前
    beforeRemove(file) {
      console.log(file);
      if (this.logFrom.title == "写日志") {
        return this.$confirm(`确定移除该文件？`).then(() => {
          this.$axios({
            url: `/common1/photos`,
            method: "DELETE",
            params: {
              bucket: "私有",
              photoIds: file.response.result
            }
          }).then(res => {

          }).catch(err => {
            this.isError(err,this)
          });
        });
      } else {
        return this.$confirm(`确定移除该文件？`).then(() => {
          this.logFrom.imgDelId.push(file.response.result);
          this.$axios({
            url: `/common1/photos/failure/${file.response.result}`,
            method: "put"
          }).then(res => {

          }).catch(err => {
            this.isError(err,this)
          });
        });
      }
    },
    //删除后
    handleRemove(file, fileList) {
      this.logFrom.imgUploadList = fileList;
    },
    //查看某张图片的原图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file);
    },
    //上传失败
    handleError(res) {
      //显示错误
      console.log(res);
    },

    //富文本
    show() {
      this.$nextTick(() => {
        this.$refs.myQuillEditor.quill
          .getModule("toolbar")
          .addHandler("image", this.imgHandler);
      });
    },
    success(res, file, fileList) {
      //图片的远程路径
      let value = "http://pic-gongkai.fangzhizun.com/" + res.key;
      // 将图片添加到富文本内容区域
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      // 调用编辑器的 insertEmbed 方法，插入URL
      this.$refs.myQuillEditor.quill.insertEmbed(
        this.addRange !== null ? this.addRange.index : 0,
        "image",
        value,
        Quill.sources.USER
      );
    },
    imgHandler(state) {
      // 点击图片ICON触发事件
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (state) {
        // 选择并上传一张图片
        let fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    //获取无回调token
    getToken2() {
      return this.$axios({
        method: "get",
        url: "/common1/photos/token",
        params: {
          bucket: "公有"
        }
      }).then(res => {
        //获取 token
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    async beforeUpload2() {
      //上传完文件前获取token
      try {
        let token = await this.getToken2();
        this.picData2.token = token;
      } catch (err) {
        console.log(err);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
>>> .ql-editor {
  height: 150px;
}
.describe /deep/.el-col {
  font-size: 14px;
}
</style>
